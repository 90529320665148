article {
  position: absolute;
  bottom: -50px;
  width: 100%;
}

.nursepage {
  padding: 0 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.linkto {
  display: flex;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--six-color);
  border-radius: 16px;
  width: max-content;
  padding: 10px 0;
}

.box.border {
  border: 2px solid var(--main-color);
}

.box .image {
  display: flex;
  justify-content: center;
}

.box .image img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
}

.box .name {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: var(--primary-color);
  margin: 0;
}

.box .special {
  font-weight: 400;
  color: var(--theme-color);
  text-align: center;
  font-size: 14px;
  margin: 0;
}

.star {
  display: flex;
  justify-content: center;
}

:disabled {
  cursor: not-allowed;
  color: transparent;
  background-color: transparent;
}

.icon {
  font-size: 60px;
  color: var(--main-color);
}

.invalid-error {
  font-size: 60px;
  color: var(--theme-color);
}

@media (max-width: 767px) {
  .nursepage {
    padding: 0;
  }
}
