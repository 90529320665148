.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.medicinebottle,
.localpharmacy,
.mapmarker {
  width: 135px;
  height: 135px;
  line-height: 170px;
  text-align: center;
  margin: 0 15px;
  background-color: var(--white);
  border: 1px solid var(--input-color);
  border-radius: 8px;
}

.icon {
  font-size: 60px;
  color: var(--theme-color);
}

.form {
  padding: 40px 0;
}

.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 50px;
}

.breadcrumb ul {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  list-style: none;
  border-radius: 0.25rem;
  width: auto;
}

.breadcrumb-item::after {
  display: inline-block;
  padding-right: 1rem;
  padding-left: 0.3rem;
  color: var(--main-color);
  content: ">";
}

.breadcrumb ul li {
  font-size: 18px;
  padding: 0 8px;
  margin: 0;
  color: var(--crumb-color);
}

.breadcrumb ul li a {
  font-size: 18px;
  margin: 0;
  padding: 0 0 8px 0;
  color: var(--crumb-color);
}

.page {
  background-color: var(--nurse-color);
  padding: 15px;
  border-radius: 15px;
}

.btn {
  width: 340px;
  height: 60px;
  background-color: var(--main-color);
  color: var(--white);
  border-radius: 8px;
}

.footer-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--nurse-color);
  padding: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.btn:disabled {
  background-color: var(--theme-color);
}

h6 {
  margin: -90px;
}

.divid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.divid p {
  text-align: center;
}

.newlink {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.newlink button {
  width: 430px;
  height: 60px;
  background-color: var(--main-color);
  border-radius: 8px;
}

.linkto {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
  color: var(--white);
  line-height: 60px;
  font-weight: 500;
}

.center {
  padding: 100px 0;
}

@media (max-width: 767px) {
  .medicinebottle,
  .localpharmacy,
  .mapmarker {
    width: 100px;
    margin: 0 10px;
  }
  .newlink button {
    width: 100%;
  }
}
