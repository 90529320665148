.search {
  position: relative;
}

.control {
  position: relative;
  width: 100%;
  height: 55px;
  margin-bottom: 15px;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  right: 30px;
}

.control input {
  width: 100%;
  height: 55px;
  padding: 0 60px;
  font-size: 16px;
  text-transform: uppercase;
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid var(--input-color);
  color: var(--icon-color);
}

.control svg {
  width: 16px;
  height: 16px;
  color: var(--icon-color);
}

.next {
  display: flex;
  justify-content: flex-end;
}

.nextbtn {
  width: 345px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color);
  margin-bottom: 15px;
}

.nearPharm {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 15px;
}

.image {
  width: 75px;
  height: 75px;
}

.image img {
  width: 75px;
  height: 75px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.discription {
  margin-inline-start: 10px;
}

.pharmName {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 500;
  margin: 0 0 0 15px;
}

.distance {
  font-size: 14px;
  color: var(--theme-color);
  font-weight: 400;
  margin: 0;
}

.radio-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item span.choose {
  display: inline-flex;
  justify-content: space-between;
  padding-left: 15px;
  align-items: center;
  width: 100%;
  height: 100px;
  font-size: 16px;
  border: 2px solid var(--fourth-color);
  margin-bottom: 10px;
  border-radius: 8px;
  color: var(--second-color);
}

.radio-item input:checked + span.choose {
  background-color: var(--main-color);
  color: var(--white);
  border-color: var(--main-color);
}

.radio-item input:checked + span.choose .distance,
.radio-item input:checked + span.choose .pharmName {
  color: var(--white);
}

@media (max-width: 767px) {
  .nextbtn {
    width: 100%;
  }
}
