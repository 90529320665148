.control {
  position: absolute;
  z-index: 100;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  width: 800px;
  height: 55px;
}

.control input {
  width: 100%;
  height: 55px;
  padding: 0 60px;
  font-size: 16px;
  text-transform: uppercase;
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid var(--input-color);
  color: var(--icon-color);
}

.control svg {
  width: 16px;
  height: 16px;
  color: var(--icon-color);
}

.search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

.chart {
  position: absolute;
  top: 35%;
  left: 30px;
  transform: rotate(-90deg);
  cursor: pointer;
}

.filter {
  position: fixed;
  z-index: 999;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.8);
}

.show {
  position: fixed;
  z-index: 1000;
  background: var(--white);
  text-align: start;
  inset: 0px;
  max-width: 1140px;
  margin: 50px auto;
  border-radius: 15px;
  padding: 15px;
  overflow-y: scroll;
}

.radio-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  font-size: 16px;
  border: 1px solid var(--fourth-color);
  margin-bottom: 10px;
  border-radius: 8px;
  color: var(--second-color);
}

.radio-item input:checked + span {
  background-color: var(--white);
  color: var(--main-color);
  border-color: var(--main-color);
}

.radio-special label {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
  color: var(--second-color);
  font-size: 16px;
}

.radio-special input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 3px;
  background-clip: content-box;
  border: 1px solid var(--main-color);
  background-color: var(--white);
  border-radius: 50%;
}

.radio-special input[type="radio"]:checked {
  background-color: var(--main-color);
}

.close {
  text-align: end;
  width: 100%;
}

.icon {
  font-size: 25px;
  color: var(--main-color);
}

.submit {
  display: flex;
  flex-direction: column;
}

.btn {
  margin: auto;
  color: var(--five-color);
  font-size: 16px;
  font-weight: 700;
}

.filter-btn {
  font-size: 16px;
  font-weight: 700;
  color: var(--white);
  text-align: center;
  width: 300px;
  height: 60px;
  background-color: var(--main-color);
  border-radius: 8px;
  margin: auto;
  margin-bottom: 25px;
}

h5 {
  font-size: 20px;
  font-weight: 700;
  color: #3f4b4c;
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  .control {
    width: 95%;
  }

  .show {
    max-width: 90%;
  }
}
