.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
}

.breadcrumb ul {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  list-style: none;
  border-radius: 0.25rem;
  width: auto;
}

.breadcrumb-item::after {
  display: inline-block;
  padding-right: 1rem;
  padding-left: 0.3rem;
  color: var(--main-color);
  content: ">";
}

.breadcrumb ul li {
  font-size: 18px;
  padding: 0 8px;
  margin: 0;
  color: var(--crumb-color);
}

.breadcrumb ul li a {
  font-size: 18px;
  margin: 0;
  padding: 0 0 8px 0;
  color: var(--crumb-color);
}

.control {
  display: flex;
  flex-direction: column;
  margin-inline-start: 20px;
}

.remove-account {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn {
  font-size: 16px;
  color: var(--main-color);
  font-weight: 500;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn:nth-last-child(2) {
  margin-inline-start: 30px;
}

.btn svg {
  color: var(--main-color);
  font-size: 25px;
  margin-inline-start: 10px;
}

.labelform {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.title {
  font-size: 14px;
  color: var(--icon-color);
}

.text {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 16px;
}

.labelform svg {
  color: var(--icon-color);
  font-size: 25px;
}

.formaction {
  border: none;
  outline: none;
  color: var(--primary-color);
  font-weight: 500;
  font-size: 16px;
}

.isedit {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.save {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: var(--main-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: var(--white);
  margin-inline-start: 10px;
  justify-content: center;
}

.cancel {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: var(--theme-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: var(--white);
  margin-inline-start: 10px;
  justify-content: center;
}

.filter {
  position: fixed;
  z-index: 999;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.8);
}

.show {
  position: fixed;
  z-index: 1000;
  background: var(--white);
  text-align: start;
  inset: 0px;
  max-width: 470px;
  height: 400px;
  margin: 150px auto;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-user {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.del {
  font-size: 24px;
  font-weight: 500;
  color: #000;
  margin: 0;
  margin-inline-end: 30px;
}

.icon {
  font-size: 16px;
}

.txt {
  color: #828282;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.form-control {
  position: relative;
  margin-bottom: 15px;
}

.form-control input {
  width: 100%;
  height: 65px;
  font-size: 16px;
  border: 1px solid var(--input-color);
  color: var(--icon-color);
  border-radius: 8px;
  outline: none;
  padding-inline-start: 40px;
}

.form-control input:focus {
  border-color: var(--main-color);
  caret-color: var(--main-color);
}

.form svg {
  font-size: 18px;
  position: absolute;
  color: var(--icon-color);
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.btns {
  width: 100%;
  height: 65px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: #ff3636;
  border-radius: 8px;
}

@media (max-width: 991px) {
  .control {
    width: 95%;
  }

  .show {
    max-width: 90%;
  }
}

@media (max-width: 767px) {
  .isedit {
    padding-top: 15px;
  }

  .save,
  .cancel {
    width: 100%;
  }

  .remove-account {
    margin-top: 30px;
  }

  .txt {
    font-size: 15px;
  }
}
