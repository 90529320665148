body.ovh {
  overflow: hidden;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 120;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
}

.popup.open {
  opacity: 1;
  visibility: visible;
}

.menu-btn,
.close-btn {
  display: none;
}

.drop {
  position: relative;
}

.drop:hover .down {
  visibility: visible;
  opacity: 1;
}

.down {
  position: absolute;
  width: 200px;
  height: 130px;
  background-color: var(--white);
  z-index: 150;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(1, 1, 1, 0.1);
  visibility: hidden;
  opacity: 0;
}

.down li {
  line-height: 40px;
}

.drop .svgDown {
  margin: 0 5px -5px 0;
}

@media (max-width: 1199px) {
  .menu-btn,
  .close-btn {
    display: block;
    padding: 10px;
  }

  .menu-btn svg,
  .close-btn svg {
    width: 16px;
    height: 16px;
    color: var(--main-color);
  }

  .drop .svgDown {
    display: none;
  }

  nav ul {
    position: fixed;
    top: 0;
    right: -260px;
    width: 260px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--white);
    text-align: center;
    padding: 30px 15px;
    gap: 10px;
    z-index: 1050;
    overflow: auto;
    transition: all 0.4s ease-in-out;
  }

  nav ul li a {
    line-height: 20px;
    padding: 5px 0 5px 5px;
  }

  .close-btn {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
  }

  nav ul.open {
    right: 0;
    animation: slide-down 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
  }

  .drop {
    position: relative;
    text-align: start;
  }

  .down {
    visibility: visible;
    opacity: 1;
    position: relative;
    text-align: start;
    box-shadow: none;
    background-color: transparent;
    width: auto;
    height: auto;
  }

  .down li {
    line-height: 50px;
  }

  .logout {
    padding: 0;
    margin: 0;
  }

  @keyframes slide-down {
    0% {
      opacity: 1;
      right: -300px;
    }
    50% {
      opacity: 1;
      right: 50px;
    }
    to {
      opacity: 1;
      right: 0;
    }
  }
}

.wrapper{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}