.lastest {
  padding: 15px 0;
}

.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.breadcrumb ul {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  list-style: none;
  border-radius: 0.25rem;
  width: auto;
}

.breadcrumb-item::after {
  display: inline-block;
  padding-right: 1rem;
  padding-left: 0.3rem;
  color: var(--main-color);
  content: ">";
}

.breadcrumb ul li {
  font-size: 18px;
  padding: 0 8px;
  margin: 0;
  color: var(--crumb-color);
}

.breadcrumb ul li a {
  font-size: 18px;
  margin: 0;
  padding: 0 0 8px 0;
  color: var(--crumb-color);
}

.image {
  width: 75px;
  height: 75px;
}

.image img {
  max-width: 100%;
  object-fit: cover;
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.last {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--third-color);
  padding-bottom: 15px;
}

.profile {
  display: flex;
  align-items: center;
}

.info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-inline-start: 15px;
}

.name {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color);
  margin: 0 0 0 15px;
}

.type {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: var(--theme-color);
  margin: 0;
  width: auto;
}

.date {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: var(--theme-color);
}

.rate button {
  width: 140px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--main-color);
  color: var(--white);
}

.link {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 55px;
}

.rate .linkdisable {
  width: 140px;
  height: 60px;
  font-weight: 700;
  border-radius: 8px;
  background-color: transparent;
  color: var(--theme-color);
}

.newlink {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newlink button {
  width: 430px;
  height: 60px;
  background-color: var(--main-color);
  border-radius: 8px;
}

.linkto {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
  color: var(--white);
  line-height: 60px;
  font-weight: 500;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.more {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 65px;
  background-color: var(--main-color);
  border-radius: 8px;
  color: var(--white);
  font-weight: 500;
  font-size: 16px;
}

:disabled {
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .center {
    padding: 15px;
  }
  .more {
    width: 100%;
  }
  .last {
    flex-direction: column;
  }
  .profile {
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
  }
  .rate {
    width: 100%;
  }
  .rate button {
    width: 100%;
  }
  .newlink button {
    width: 100%;
  }
}
