.valid {
  color: #ff0000;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: #000;
}

.order {
  margin-bottom: 15px;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.edit {
  font-size: 16px;
  font-weight: 700;
  color: #2c2f31;
  margin: 0;
  padding: 0;
}

.isedit svg {
  color: var(--main-color);
  cursor: pointer;
  font-size: 25px;
}

.editaddress {
  padding: 15px;
  width: 100%;
  min-height: 145px;
  border: 1px solid #eaeeed;
  margin-bottom: 15px;
  background-color: var(--white);
  border-radius: 8px;
}

.isedit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.editaddress label {
  color: #6c7174;
  font-size: 16px;
  font-weight: 400;
}

.input {
  width: 100%;
  outline: none;
  border: 1px solid var(--main-color);
  height: 55px;
  padding: 0 15px;
}

.next {
  display: flex;
  justify-content: flex-end;
}

.nextbtn {
  width: 345px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}

.add-address {
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .nextbtn {
    width: 100%;
  }
}
