.suggest {
  padding: 20px 0;
}

.nursesuggest {
  padding: 0 50px;
}

.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.breadcrumb ul {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  list-style: none;
  border-radius: 0.25rem;
  width: auto;
}

.breadcrumb-item::after {
  display: inline-block;
  padding-right: 1rem;
  padding-left: 0.3rem;
  color: var(--main-color);
  content: ">";
}

.breadcrumb ul li {
  font-size: 18px;
  padding: 0 8px;
  margin: 0;
  color: var(--crumb-color);
}

.breadcrumb ul li a {
  font-size: 18px;
  margin: 0;
  padding: 0 0 8px 0;
  color: var(--crumb-color);
}

.name {
  color: var(--seven-color);
  font-size: 20px;
  font-weight: 700;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.control {
  position: relative;
  margin-bottom: 25px;
}

.suggest input {
  padding: 0 40px 0 0;
  width: 540px;
  height: 65px;
  outline: none;
  border: 1px solid var(--input-color);
  border-radius: 20px;
  color: var(--icon-color);
}

.age input {
  padding: 0 40px 0 0;
  width: 100%;
  height: 65px;
  outline: none;
  border: 1px solid var(--input-color);
  border-radius: 20px;
  color: var(--icon-color);
}

.btn {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  width: 360px;
  height: 60px;
  border-radius: 8px;
  color: var(--white);
  background-color: var(--main-color);
}

.action {
  display: flex;
  justify-content: end;
}

.suggest svg {
  font-size: 18px;
  position: absolute;
  color: var(--icon-color);
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

@media (max-width: 1199px) {
  .controls {
    flex-direction: column;
  }
  .control {
    width: 100%;
  }
  .suggest input {
    width: 100%;
  }
}

.prop-name {
  font-weight: 500;
  font-size: 18px;
  color: #000;
}

.radio-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.radio-item input[type="checkbox"],
.radio-item input[type="radio"] {
  display: none;
}

.radio-item .contnet-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
}

.radio-item .item {
  min-width: 200px;
  height: 65px;
  flex: 1;
}

.radio-item span.choose {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  height: 65px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--fourth-color);
  margin-bottom: 10px;
  border-radius: 8px;
  color: var(--second-color);
}

.radio-item input:checked + span.choose {
  background-color: var(--main-color);
  color: var(--white);
  border-color: var(--main-color);
}

.radio-item input:checked + span.choose .distance,
.radio-item input:checked + span.choose .pharmName {
  color: var(--white);
}

@media (max-width: 767px) {
  h2 {
    margin-bottom: 15px;
  }
  .btn {
    width: 100%;
  }
  .control {
    margin-bottom: 10px;
  }
  .expand {
    width: 100%;
  }
}
