.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image {
  width: 50%;
  position: relative;
}

.image img {
  width: 100%;
}

.form {
  width: 50%;
  position: relative;
}

.form h2 {
  padding: 0;
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 33px;
  color: var(--primary-color);
}

.form p {
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  margin-bottom: 35px;
  color: var(--theme-color);
}

.form-control {
  position: relative;
  width: 340px;
  margin-bottom: 15px;
}

.form-control input {
  width: 100%;
  height: 65px;
  font-size: 16px;
  border: 1px solid var(--input-color);
  color: var(--icon-color);
  border-radius: 8px;
  outline: none;
  padding-inline-start: 40px;
}

.form-control input:focus {
  border-color: var(--main-color);
  caret-color: var(--main-color);
}

.control {
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.form svg {
  font-size: 18px;
  position: absolute;
  color: var(--icon-color);
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.btn {
  width: 340px;
  height: 65px;
  margin: 10px 0 30px;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  border: 1px solid var(--main-color);
}

.facebtn {
  width: 340px;
  height: 65px;
  margin-bottom: 25px;
  font-size: 16px;
  background-color: var(--facebook-color);
  color: var(--white);
  border: 1px solid var(--facebook-color);
  border-radius: 8px;
  cursor: pointer;
}

.face {
  position: relative;
  top: 20%;
  right: -5px;
  background-color: var(--facebook-color);
  color: var(--white);
}

.toggle {
  background: transparent;
  outline: none;
  border: 0;
  font-size: 16px;
  color: var(--theme-color);
  text-align: center;
}

.toggle span {
  color: var(--main-color);
  cursor: pointer;
}

.overlay {
  position: absolute;
  inset: 0px;
  mix-blend-mode: multiply;
  background: var(--main-color);
}

.error {
  color: var(--error);
}

.forget {
  text-align: center;
  outline: none;
  border: 0;
  background: transparent;
  font-size: 16px;
  color: var(--main-color);
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 200px;
}

.forgettext {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: var(--black);
}

.enter {
  font-size: 18px;
  color: #a6a9ac;
  font-weight: 500;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .controls {
    display: block;
  }
  .form {
    width: 100%;
  }
  .image {
    display: none;
  }
  .control {
    transform: translate(-50%, 0);
    padding: 10px 0;
  }
  .forget {
    margin: 0 0 15px;
  }
  .login {
    margin-bottom: 20px;
  }
}
