.term {
  padding-top: 20px;
  padding-bottom: 50px;
}

.term .first {
  padding: 0 20px 20px 20px;
  background-color: var(--white);
}

.term .condition {
  margin-bottom: 30px;
}

.term p.sub-title {
  font-size: 17px;
  color: var(--theme-color);
  line-height: 30px;
}

.term .link {
  font-size: 26px;
  color: var(--main-color);
  line-height: 40px;
}

.term li {
  line-height: 30px;
  font-size: 20px;
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .term .link {
    font-size: 22px;
    line-height: 25px;
  }

  .term li {
    line-height: 25px;
    font-size: 15px;
    margin-bottom: 5px;
  }
}
