.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
}

.breadcrumb ul {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  list-style: none;
  border-radius: 0.25rem;
  width: auto;
}

.breadcrumb-item::after {
  display: inline-block;
  padding-right: 1rem;
  padding-left: 0.3rem;
  color: var(--main-color);
  content: ">";
}

.breadcrumb ul li {
  font-size: 18px;
  padding: 0 8px;
  margin: 0;
  color: var(--crumb-color);
}

.breadcrumb ul li a {
  font-size: 18px;
  margin: 0;
  padding: 0 0 8px 0;
  color: var(--crumb-color);
}

.price {
  font-size: 20px;
  font-weight: 700;
  color: var(--seven-color);
  font-weight: 700;
}

.pricing {
  padding: 0 150px;
}

.special {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--third-color);
}

.title {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 500;
}

.text {
  font-size: 16px;
  color: var(--theme-color);
}

@media (max-width: 767px) {
  .pricing {
    padding: 0 15px;
  }
}
