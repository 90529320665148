.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.breadcrumb ul {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  list-style: none;
  border-radius: 0.25rem;
  width: auto;
}

.breadcrumb-item::after {
  display: inline-block;
  padding-right: 1rem;
  padding-left: 0.3rem;
  color: var(--main-color);
  content: ">";
}

.breadcrumb ul li {
  font-size: 18px;
  padding: 0 8px;
  margin: 0;
  color: var(--crumb-color);
}

.breadcrumb ul li a {
  font-size: 18px;
  margin: 0;
  padding: 0 0 8px 0;
  color: var(--crumb-color);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stars {
  display: flex;
  justify-content: center;
  align-items: center;
}

.next {
  display: flex;
  justify-content: flex-end;
}

.nextbtn {
  width: 345px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}

.last {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}

.profile {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-inline-start: 15px;
}

.name {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color);
  margin: 0 0 0 15px;
}

.type {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: var(--theme-color);
  margin: 0;
  width: auto;
}

.image {
  width: 75px;
  height: 75px;
}

.image img {
  max-width: 100%;
  object-fit: cover;
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .last {
    padding-top: 15px;
  }

  .nextbtn {
    width: 100%;
  }
}
