.nurse-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0;
}

.services {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}

h1 {
  font-size: 40px;
  font-weight: 700;
  color: var(--section-color);
  margin: 0 0 10px;
}

.getorder {
  font-size: 24px;
  color: var(--second-color);
  margin: 10px 0;
}

.name {
  width: 350px;
  color: var(--second-color);

  margin-bottom: 35px;
}

.link {
  width: 350px;
  height: 55px;
  background-color: var(--main-color);
  text-align: center;
  border-radius: 8px;
  line-height: 50px;
}

.link a {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: 700;
  color: var(--white);
}

.nurse-image {
  position: absolute;
  top: 35px;
  left: 0;
}

.nurse-image img {
  border-radius: 0px 16px 16px 0px;
}

.pharm-page {
  padding: 60px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pharm-image {
  position: absolute;
  top: 50px;
  left: 0;
}

.pharm-image img {
  border-radius: 0px 16px 16px 0px;
}

.check-order {
  margin: 75px 0 50px;
  padding: 60px 0;
  display: flex;
  background: rgba(2, 176, 177, 0.12);
}

.order {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1290px) {
  .nurse-page,
  .pharm-page,
  .order {
    flex-direction: column;
  }

  .nurse-page,
  .pharm-page {
    padding: 35px 0;
  }

  .nurse-image,
  .pharm-image {
    position: static;
  }

  .nurse-image img,
  .pharm-image img {
    max-width: 100%;
    border-radius: 16px;
  }

  .check-order {
    margin: 30px 0;
    padding: 30px 0;
  }

  .services,
  .text {
    margin-bottom: 25px;
    justify-content: center;
    align-items: center;
    text-align: justify;
  }

  .getorder {
    margin-top: 0;
  }

  .name {
    margin: 0 0 25px;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 35px;
  }
  figure {
    height: 70px;
  }
  figure a {
    display: inline-block;
  }
  .name {
    width: 100%;
  }
  .link {
    width: 100%;
  }
}
