.map {
  position: relative;
}

.location {
  position: relative;
  cursor: pointer;
  display: block;
}

.description {
  width: 200px;
  height: 150px;
  position: absolute;
  display: block;
  top: -100px;
  left: 0;
  background-color: var(--white);
  border-radius: 8px;
}

.nurse-image {
  width: 200px;
  height: 100px;
}

.nurse-image img {
  width: 200px;
  height: 100px;
  max-width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 30px;
  color: var(--main-color);
}

.description h2 {
  text-align: center;
  margin: 10px 0 0 0;
  color: var(--black);
}

.linkto {
  width: max-content;
  display: inline-block;
  color: var(--black);
  text-align: center;
}
