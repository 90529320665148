body {
  font-family: "SST Arabic", sans-serif !important;
  direction: rtl;
  text-align: start;
  margin: 0;
  padding: 0;
}

button {
  font-family: "SST Arabic", sans-serif !important;
}

:root {
  --main-color: #02b0b1;
  --primary-color: #2c2f31;
  --theme-color: #6c7174;
  --white: #fff;
  --input-color: #eaeeed;
  --icon-color: #9a9da0;
  --crumb-color: #818686;
  --nurse-color: #f9fcfd;
  --section-color: #3f4b4d;
  --second-color: #828282;
  --third-color: #e0e0e0;
  --fourth-color: #d9d9d9;
  --five-color: #333333;
  --six-color: #fdfdfd;
  --seven-color: #3f4b4c;
  --facebook-color: #0577fb;
  --black: #000000;
  --error: #f93e3e;
  --invalid: #feebeb;
  --loading: #ccc;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a:hover {
  text-decoration: none;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}

.section {
  position: relative;
}

@font-face {
  font-family: "SST Arabic";
  font-weight: 700;
  src: url("../src/fonts/SST Arabic Bold.ttf"),
    url("../src/fonts/SSTArabic-Bold.woff2"),
    url("../src/fonts/SSTArabic-Bold.woff");
}

@font-face {
  font-family: "SST Arabic";
  font-weight: 400;
  src: url("../src/fonts/SST Arabic Roman.ttf"),
    url("../src/fonts/SSTArabic-Roman.woff2"),
    url("../src/fonts/SSTArabic-Roman.woff");
}

@font-face {
  font-family: "SST Arabic";
  font-weight: 500;
  src: url("../src/fonts/SST Arabic Medium.ttf"),
    url("../src/fonts/SSTArabic-Medium.woff2"),
    url("../src/fonts/SSTArabic-Medium.woff");
}

@font-face {
  font-family: "SST Arabic";
  font-weight: 300;
  src: url("../src/fonts/SST Arabic Light.ttf"),
    url("../src/fonts/SSTArabic-Light.woff2"),
    url("../src/fonts/SSTArabic-Light.woff");
}

textarea,
input {
  font-family: "SST Arabic";
}

@media (min-width: 767px) {
  .swiper-slide {
    width: max-content !important;
  }
}

.errorMessage {
  color: var(--error);
}

.loading {
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 3px solid var(--loading);
  border-top-color: var(--main-color);
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
