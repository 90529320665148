.invalid {
  border: 1px solid var(--error) !important;
  background-color: var(--invalid);
}

.formaction {
  border: none;
  outline: none;
  color: var(--primary-color);
  font-weight: 500;
  font-size: 16px;
  width: 340px;
}

.formaction:focus {
  caret-color: var(--main-color);
}
