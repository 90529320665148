.my-facebook-button {
  width: 340px !important;
  height: 65px;
  margin-bottom: 25px;
  font-size: 16px;
  background-color: var(--facebook-color);
  color: var(--white);
  border: 1px solid var(--facebook-color);
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  font-family: "SST Arabic", sans-serif !important;
}

@media (max-width: 767px) {
  .my-facebook-button {
    width: 100% !important;
  }
}
