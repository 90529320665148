header {
  background-color: var(--white);
  box-shadow: 0px 4px 20px rgba(147, 152, 153, 0.12);
}

.logo {
  margin: 0;
  padding: 0;
  display: flex;
}

nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}

.navbar {
  display: flex;
  justify-content: space-between;
}

nav li {
  list-style: none;
}

nav ul li {
  line-height: 85px;
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  width: 36px;
  height: 36px;
  color: var(--main-color);
}

li a {
  padding: 5px 15px;
  margin: 0 20px;
  color: var(--theme-color);
  font-size: 16px;
}

.logout {
  padding: 5px 15px;
  margin: 0 20px;
  color: var(--theme-color);
  font-size: 16px;
  font-weight: 400;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.logout:hover,
li a:hover,
li a.active {
  color: var(--main-color);
}

figure {
  display: none;
  margin: 0;
  line-height: 85px;
}

@media (max-width: 1199px) {
  figure {
    display: block;
  }

  .menu-btn,
  .close-btn {
    display: block;
    padding: 10px;
  }

  .menu-btn svg,
  .close-btn svg {
    width: 16px;
    height: 16px;
    color: var(--main-color);
  }

  .main {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .logo {
    display: none;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul {
    position: fixed;
    top: 0;
    right: -260px;
    width: 260px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: var(--white);
    text-align: center;
    padding: 30px 15px;
    gap: 10px;
    z-index: 1050;
    overflow: auto;
    transition: all 0.4s ease-in-out;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  nav ul li a {
    line-height: 20px;
    padding: 0;
    margin: 0;
  }

  nav ul li {
    line-height: 50px;
  }

  .close-btn {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
  }

  nav ul.open {
    right: 0;
    animation: slide-down 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
  }

  .drop {
    position: relative;
    text-align: start;
  }

  .down {
    visibility: visible;
    opacity: 1;
    position: relative;
    text-align: start;
  }

  .logout {
    padding: 0;
    margin: 0;
  }

  @keyframes slide-down {
    0% {
      opacity: 1;
      right: -300px;
    }
    50% {
      opacity: 1;
      right: 50px;
    }
    to {
      opacity: 1;
      right: 0;
    }
  }
}
