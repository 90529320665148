footer {
  background-color: var(--white);
  box-shadow: 0px 4px 20px rgba(59, 61, 62, 0.12);
  padding: 0px 35px;
  margin-top: 40px;
}
.footerDiv{
  display: flex;
    justify-content: space-between;
}
.footerIcons{
  gap:15px;
  display: flex;
}
.facebook{
  height: 35px;
}
.appstore{
  height: 36px;
}
.googleplay{
  height: 35px;
}
.instagram{
  height:35px
}
.whatsapp{
  height:35px
}
.poweredby{
  margin-top: 46px;
  font-size: 11px;
}