.section {
  padding-bottom: 50px;
}

.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.breadcrumb ul {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  list-style: none;
  border-radius: 0.25rem;
  width: auto;
}

.breadcrumb-item::after {
  display: inline-block;
  padding-right: 1rem;
  padding-left: 0.3rem;
  color: var(--main-color);
  content: ">";
}

.breadcrumb ul li {
  font-size: 18px;
  padding: 0 8px;
  margin: 0;
  color: var(--crumb-color);
}

.breadcrumb ul li a {
  font-size: 18px;
  margin: 0;
  padding: 0 0 8px 0;
  color: var(--crumb-color);
}

.nurseProfile {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 35px;
  color: var(--black);
}

.profile {
  background-color: var(--nurse-color);
  border-radius: 24px;
}

.nurse {
  width: 100%;
}

.profile-info {
  display: flex;
  padding: 50px 100px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.box {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
}

.contact {
  display: flex;
  flex-direction: column;
}

.contact span {
  color: var(--theme-color);
  font-size: 16px;
}

.name {
  font-size: 16px;
  margin: 0;
  color: var(--primary-color);
  font-weight: 500;
}

.contact p {
  color: var(--theme-color);
  font-size: 16px;
  margin-bottom: 15px;
}

.contact .link {
  width: 340px;
  height: 55px;
  background-color: var(--main-color);
  border-radius: 8px;
}

.contact .link a {
  width: 100%;
  height: 100%;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  color: var(--white);
}

.image {
  width: 200px;
  height: 200px;
  display: flex;
}

.image img {
  width: 100%;
  max-width: 100%;
  border-radius: 50%;
}

.review-nurse .name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: var(--primary-color);
}

.review-nurse {
  padding: 0 100px;
}

.review {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review:not(:last-of-type) {
  border-bottom: 1px solid var(--third-color);
}

.rate {
  display: flex;
  flex-shrink: 0;
}

h5 {
  font-size: 16px;
  color: var(--primary-color);
  margin: 0 0 10px;
}

.nurse p {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: var(--theme-color);
}

.newlink {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.newlink button {
  width: 340px;
  height: 60px;
  background-color: var(--main-color);
  border-radius: 8px;
}

.linkto {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
  color: var(--white);
  line-height: 60px;
  font-weight: 500;
}

@media (max-width: 767px) {
  .box {
    flex-direction: column;
    align-items: center;
  }
  .nurse .link {
    width: 100%;
  }

  .profile-info {
    padding: 40px 0;
  }

  .contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .review-nurse {
    padding: 0;
  }

  .nurse {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nurse p {
    text-align: center;
  }

  .review {
    flex-direction: column;
  }

  .newlink button {
    width: 100%;
  }
}
