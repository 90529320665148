.image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 0;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.message {
  text-align: center;
  width: 300px;
}

.link {
  display: inline-block;
  width: 345px;
  height: 60px;
  line-height: 60px;
  border-radius: 8px;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .link {
    width: 100%;
  }
}
