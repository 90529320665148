.success {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 0;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.message {
  text-align: center;
  width: 300px;
}

.link {
  display: inline-block;
  width: 345px;
  height: 60px;
  line-height: 60px;
  border-radius: 8px;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}

.image img {
  max-width: 100%;
  object-fit: cover;
  background-color: var(--white);
}

@media (max-width: 767px) {
  .link {
    width: 100%;
  }
}
