textarea {
  width: 100%;
  height: 200px;
  border-radius: 15px;
  border: 1px solid var(--third-color);
  padding: 15px;
  outline: none;
}

textarea:focus {
  border-color: var(--main-color);
  caret-color: var(--main-color);
}

.valid {
  color: var(--error);
}

.text {
  color: var(--main-color);
}

.image {
  width: 200px;
  height: 200px;
  margin: 10px;
  position: relative;
}

.image img {
  width: 200px;
  height: 200px;
  border-radius: 8px;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: var(--main-color);
}

.file-upload-label {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 700;
  color: var(--main-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.file-upload-label img {
  margin-inline-end: 10px;
}

.file-upload-label svg {
  margin-inline-end: 10px;
  font-size: 25px;
  color: var(--main-color);
}

.file-upload-input {
  display: none;
}

.title {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.order {
  margin-bottom: 15px;
  padding: 0;
}

.next {
  display: flex;
  justify-content: flex-end;
}

.nextbtn {
  width: 345px;
  height: 60px;
  border-radius: 8px;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 767px) {
  .nextbtn {
    width: 100%;
  }
  .file-upload-label {
    margin-bottom: 10px;
  }
}
